html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

body {
  background: linear-gradient(121deg, #0D5673, #79DCF2);
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  text-align: center;
  line-height: 1.5;
}

h1.title {
  margin: 0;
  font-size: 48px;
  font-family: 'Caveat', sans-serif;
  text-align: center;
}

.subtitle {
  margin: 0 auto;
  font-size: 16px;
}

.link,
a {
  color: #F25CA2;
  font-weight: 500;
  text-decoration: none;
}

button.link {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: none;
  cursor: pointer;
}

button.link:focus {outline:0;}

.card {
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 32px;
  border-radius: 16px;
}

.card p {
  margin-bottom: 16px;
}

.card p:last-child {
  margin-bottom: 0;
}

.card img.logo {
  width: 50%;
  margin: 0 auto 16px;
}